<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          クーポン一覧
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md="3">
              <CButton type="submit" color="info" style="width:300px;" @click="downloadCSV()">
                CSVダウンロード
              </CButton>
            </CCol>
            <CCol md="3">
              <CSelect
                :options="sortOptions"
                :value.sync="sortType"
                />
            </CCol>
            <CCol md="3">
              <CSelect
                :options="downloadOptions"
                :value.sync="downloadType"
                />
            </CCol>
          </CRow>
          <CDataTable
            :items="usersData"
            :fields="fields"
            column-filter
            table-filter
            items-per-page-select
            :items-per-page="10"
            hover
            sorter
            pagination
          >
            <template #status="{item}">
              <td class="text-right">
                <CBadge :color="getBadge(item.status)">
                  {{getStatusText(item.status)}}
                </CBadge>
              </td>
            </template>

            <template #discount="{item}">
              <td class="text-right">
                {{item.discount}}
              </td>
            </template>

            <template #maker_id="{item}">
              <td class="text-right">
                {{getMakerName(item.maker_id)}}
              </td>
            </template>

            <template #start_date="{item}">
              <td class="text-right">
                {{item.start_date}}
              </td>
            </template>

            <template #end_date="{item}">
              <td class="text-right">
                {{item.end_date}}
              </td>
            </template> 

            <template #show_details="{item, index}">
              <td class="py-2 text-right">
                <CButton
                  color="info"
                  variant="outline"
                  square
                  size="sm"
                  @click="toggleDetails(item.id)"
                >
                  {{details.includes(index) ? 'Hide' : '編集'}}
                </CButton>
              </td>
            </template>
            <template #details="{item, index}">
              <CCollapse :show="details.includes(index)">
                <CCardBody>
                  {{index + 1}} - {{item}}
                </CCardBody>
              </CCollapse>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import firebase from '@firebase/app';
import { UserRole } from "@/common/const";

const fields = [
  { key: 'title',　label: 'タイトル',  _style:'width:25%; text-align:center;' },
  { key: 'discount',　label: '割引金額', _style:'width:10%; text-align:center;' },
  { key: 'maker_id',　label: '発行メーカー', _style:'width:15%; text-align:center;' },
  { key: 'start_date',　label: '開始日', _style:'width:15%; text-align:center;' },
  { key: 'end_date',　label: '終了日', _style:'width:15%; text-align:center;' },
  { key: 'status',　label: '公開', _style:'width:10%; text-align:center;' },
  { 
    key: 'show_details', 
    label: '', 
    _style: 'width:10%', 
    sorter: false, 
    filter: false
  }
]

export default {
  name: 'AdvancedTables',
  data () {
    return {
      usersData: [],
      fields,
      details: [],
      makers: [],
      account: {},
      sortType: 1,
      sortOptions: [
        { value: 1, label: "日付（降順）" },
        { value: 2, label: "日付（昇順）" },
        { value: 3, label: "公開ステータス（降順）" },
        { value: 4, label: "公開ステータス（昇順）" },
      ],
      downloadType: 0,
      downloadOptions: [
        { value: 0, label: "すべて" },
        { value: 1, label: "公開" },
        { value: 2, label: "非公開" },
        { value: 3, label: "終了" },
      ],
    }
  },
  methods: {
    getBadge (status) {
      return status === 1 ? 'success'
             : status === 2 ? 'secondary'
             : status === 3 ? 'warning'
             : status === 'Banned' ? 'danger' : 'primary'
    },
    getStatusText (status) {
      return status === 1 ? '公開'
             : status === 2 ? '非公開'
             : status === 3 ? '終了' : 'エラー'
    },
    toggleDetails (id) {
      const link = `/coupons/${id}`
      this.$router.push({path: link})
    },
    async init () {
      let uid
      uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      let event = firebase.app().functions('asia-northeast1').httpsCallable('me')

      this.$store.commit("showLoading")

      // ユーザーデータ取得
      await event({uid: uid}).then(function(res) {
        this.account = res.data.user
        this.$store.commit("hideLoading")
      }.bind(this));

      // メーカー一覧取得
      const makerNum = 2
      firebase.firestore().collection('users').where("role", "==", makerNum).get().then(function(result) {
        let tmp = []
        result.forEach(function(doc) {
          tmp.push(doc.data())
        })
        this.makers = tmp
      }.bind(this))

      let couponDocs = [];

      // クーポン一括取得
      if (this.account.role != UserRole.REGISTER) {
        let couponDoc = await firebase.firestore().collection('coupons').get()
        couponDocs = couponDoc.docs
      }

      let tmp = []
      for (let doc of couponDocs) {
        let coupon = doc.data()
        // format
        if (coupon.start_date !== '' && typeof(coupon.start_date) === 'object') {
          const startDate = coupon.start_date.toDate()
          coupon.start_date = startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2)
        }
        if (coupon.end_date !== '' && typeof(coupon.end_date) === 'object') {
          const endDate = coupon.end_date.toDate()
          coupon.end_date = endDate.getFullYear() + '-' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)
        }
        if (this.isAdmin()) {
          if (!tmp.some(t => t.id === coupon.id)) {
            tmp.push(coupon)
          }
        } else if (this.isDistribution()) {
          let includeUseDistribution = coupon.use_distribution_ids != null && coupon.use_distribution_ids.includes(uid)
          if (includeUseDistribution && !tmp.some(t => t.id === coupon.id)) {
            tmp.push(coupon)
          }
        } else if (this.isMakers()) {
          let includeMaker = coupon.maker_id != null && coupon.maker_id == uid
          if (includeMaker && !tmp.some(t => t.id === coupon.id)) {
            tmp.push(coupon)
          }
        } else if (this.isWholeSales()) {
          let includeWholeSales = coupon.wholesaleFlag && coupon.wholesale_id != null && coupon.wholesale_id.includes(uid)
          if (includeWholeSales && !tmp.some(t => t.id === coupon.id)) {
            tmp.push(coupon)
          }
        }
      }
      this.usersData = tmp
    },
    getMakerName(id) {
      const maker = this.makers.find(maker => maker.id === id)
      if (maker === undefined) {
        return ''
      } else {
        return maker.name
      }
    },
    async getUid() {
      let uid
      uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      return uid
    },
    isAdmin() {
      return this.account.role == UserRole.ADMIN;
    },
    isDistribution() {
      return this.account.role == UserRole.DISTRIBUTIONS
    },
    isMakers() {
      return this.account.role == UserRole.MAKERS
    },
    isWholeSales() {
      return this.account.role == UserRole.WHOLESALE
    },
    isRegister() {
      return this.account.role == UserRole.REGISTER
    },
    async downloadCSV () {
      // ひもづけ小売情報取得
      let storeNamesMap = {}
      let distributionSnapshot = null;
      const uid = await this.getUid()
      const userSnapShot = await firebase.firestore()
        .collection("users")
        .doc(uid)
        .get();

      const userRole = userSnapShot.data().role;
      if (userRole == UserRole.ADMIN) {
        distributionSnapshot = await firebase.firestore().collection("users").where("role", "==", UserRole.DISTRIBUTIONS).get()
      } else if (userSnapShot.data().role == UserRole.DISTRIBUTIONS) {
        distributionSnapshot = await firebase.firestore().collection('users').where("id", "==", uid).get()
      } else {
        const uid = await this.getUid()
        const distributionSubcollection = await firebase.firestore().collection('users').doc(uid).collection('distributions').get();
        const distributionIds = distributionSubcollection.docs.map(d => d.id);
        distributionSnapshot = await firebase.firestore().collection('users').where("id", "in", distributionIds).get();
      }
      
      for (const distributionDoc of distributionSnapshot.docs) {
        let distribution = {}
        const distributionUser = distributionDoc.data()
        distribution.id = distributionDoc.id
        distribution.name = distributionUser.name
        const parentId = distributionUser.parent_id
        if (parentId != null && parentId != "") {
          continue;
        }
        if (this.isAdmin() || this.isWholeSales() || this.isDistribution()) {
          const storesSnapshot = await firebase.firestore().collection('stores').where('companyCode', '==', distributionUser.companyCode).get()
          for (const storeDoc of storesSnapshot.docs) {
            const storeCouponSnapshot = await firebase.firestore().collection('stores').doc(storeDoc.id).collection('storeCoupons').get()
            for (const storeCouponDoc of storeCouponSnapshot.docs) {
              if (storeNamesMap[storeCouponDoc.id] === undefined) {
                storeNamesMap[storeCouponDoc.id] = [storeDoc.data()]
              } else {
                storeNamesMap[storeCouponDoc.id] = storeNamesMap[storeCouponDoc.id].concat(storeDoc.data())
              }
            }
          }
        }
      }

      const genres = await firebase.firestore().collection("genres").get()
      let genreDatas = [];
      for (let c of genres.docs) {
        let data = c.data()
        data.id = c.id
        genreDatas.push(data)
      }
      const categories = await firebase.firestore().collection("categories").get()
      let categoryDatas = [];
      for (let c of categories.docs) {
        let data = c.data()
        data.id = c.id
        categoryDatas.push(data)
      }
      const coupons = await firebase.firestore().collection("coupons").get()
      let couponDatas = [];
      for (let c of coupons.docs) {
        couponDatas.push(c.data())
      }

      let csv = "";

      csv = '\ufeff' + '内部ID,クーポンID,クーポン名,商品規格,カテゴリ,酒類,メーカー名,卸発行クーポン,小売発行クーポン,クーポン説明,クーポン画像,' +
          '有効期限（開始日）,有効期限（終了日）,JANコード,割引金額,発行枚数（0は無制限）,注意事項,再取得可能,１精算につき、買上げ全商品対象,公開ステータス,有効店舗'
      csv += '\r\n'

      couponDatas.filter(c => {
        if (this.downloadType == 1) {
          return c.status == 1
        } else if (this.downloadType == 2) {
          return c.status == 2
        } else if (this.downloadType == 3) {
          return c.status == 3
        }
      }).sort((a, b) => {
        let startDate1 = a.start_date.toDate()
        let startDate2 = b.start_date.toDate()
        if (this.sortType == 1) {
          if (startDate1 > startDate2) {
            return -1
          } else if (startDate1 < startDate2) {
            return 1
          } else {
            return 0
          }
        } else if (this.sortType == 2) {
          if (startDate1 > startDate2) {
            return 1
          } else if (startDate1 < startDate2) {
            return -1
          } else {
            return 0
          }
        } else if (this.sortType == 3) {
          if (a.status > b.status) {
            return 1
          } else if (a.status < b.status) {
            return -1
          } else {
            return 0
          }
        } else if (this.sortType == 4) {
          if (a.status < b.status) {
            return 1
          } else if (a.status > b.status) {
            return -1
          } else {
            return 0
          }
        }
        return 0;
      }).forEach(c => {
        let genre = c.genre !== undefined ? genreDatas.find(d => d.id === c.genre.id) : null
        let category = c.category !== undefined ? categoryDatas.find(d => d.id === c.category.id) : null
        let startDate = c.start_date.toDate()
        let startDateStr =
                    startDate.getFullYear() +
                    "-" +
                    ("0" + (startDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + startDate.getDate()).slice(-2)
        let endDate = c.end_date.toDate()
        let endDateStr =
                    endDate.getFullYear() +
                    "-" +
                    ("0" + (endDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + endDate.getDate()).slice(-2);
        let storeNames = (storeNamesMap[c.id] !== undefined ? storeNamesMap[c.id].sort((a, b) => {
              if (a.companyCode > b.companyCode) {
                return 1
              } else if (a.companyCode < b.companyCode) {
                return -1
              } else {
                return a.storeCore > b.storeCore ? 1 : -1
              }
            }).map(s => s.name).join(',') : "")
        var line = c.id + "," +
            c.jan_code + ('000000' + c.couponCode).slice(-6) + "," +
            c.title + "," +
            c.subTitle + "," +
            (genre ? genre.name  : "") + "/" + (category ? category.name : "") + "," +
            (c.isAlcohol ? "1" : "") + "," +
            this.getMakerName(c.maker_id) + "," +
            (c.wholesaleFlag ? "1" : "") + "," +
            (c.distributionFlag ? "1" : "") + "," +
            (c.description !== undefined ? c.description.replaceAll('\n', ' ') : "") + "," +
            c.image + "," +
            startDateStr + "," +
            endDateStr + "," +
            c.jan_code + "," +
            c.discount + "," +
            c.limitNum + "," +
            (c.notice !== undefined ? c.notice.replaceAll('\n', ' ') : "") + "," +
            (c.reacquisitionFlag ? "1" : "") + "," +
            (c.onePerCustomerFlag ? "1" : "") + "," +
            this.getStatusText(c.status) + "," +
            storeNames
        csv += line + '\r\n'
      });
 
      let blob = new Blob([csv], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Coupons.csv'
      link.click()
    },
  },
  created () {
    this.init()
  },
  watch: {
    '$route': function () {
      this.init()
    }
  },
}
</script>
